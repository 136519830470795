
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import EnumerationApiService from "../../services/EnumerationApiService";
import Enumeration from "../../types/Enumeration/Enumeration";
import EnumerationList from "../../types/Enumeration/EnumerationList";
import EnumerationListItem from "../../types/Enumeration/EnumerationListItem";
import Guid from "../../types/Guid";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import VMSSelect from "@/components/fields/VMSSelect.vue";

@Component({
  components: {
    VMSTextField,
    VMSSelect
  }
})
export default class EnumerationListCreate extends Vue {
  //@Prop(Boolean) value: Boolean | undefined;
  apiService: EnumerationApiService = new EnumerationApiService();
  enumerations: Enumeration[] = [];
  enumHeaders: object[] = [
    {
      text: this.$root.$t("Text"),
      align: "left",
      value: "Text"
    },
    {
      text: this.$root.$t("Description"),
      align: "left",
      value: "Description"
    },
    {
      text: this.$root.$t("Value"),
      align: "left",
      value: "Value"
    },
    {
      text: "",
      align: "right",
      value: "Actions"
    }
  ];

  systemListNames: string[] = [
    "IndigenousTypes",
    "Grades",
    "Genders",
    "LocationTypes",
    "Languages",
    "AreasOfStudy",
    "TrainingDelivered",
    "TrainingDeliveryFormats",
    "SkillsAndCompetencies",
    "Conferences",
    "WorkSituations",
    "LevelsOfStudy",
    "VolunteeringOpportunities",
    "Pronouns",
    "Provinces",
    "EthnicDescriptors",
    "YesNo",
    "YesNoMaybe",
    "SiteOutreachRequestIntakeReasons",
    "GenderIdentities",
    "PopulationDensities",
    "YouthGroupGenderIdentity",
    "SpecialInitiativesTags",
    "VolunteerLocations",
    "KitDurations"
  ];

  existingSystemLists: string[] = [];

  get SystemNames(): object[] {
    let ret: object[] = this.systemListNames
      .filter(x => !this.existingSystemLists.includes(x))
      .map(x => {
        return {
          text: this.$root.$t(`${x}_SystemName`),
          value: x
        };
      });

    return ret;
  }

  enumListHeaders: object[] = [
    {
      text: this.$root.$t("Text"),
      align: "left",
      value: "Text",
      sortable: false
    },
    {
      text: this.$root.$t("Description"),
      align: "left",
      value: "Enumeration.Description",
      sortable: false
    },
    {
      text: this.$root.$t("Value"),
      align: "left",
      value: "Enumeration.Value",
      sortable: false
    },
    {
      text: this.$root.$t("ConditionalTrigger"),
      align: "left",
      value: "ConditionalTrigger"
    },
    {
      text: "",
      align: "right",
      value: "MoveActions",
      sortable: false
    },
    {
      text: "",
      align: "right",
      value: "Actions",
      sortable: false
    }
  ];

  enumList: EnumerationList = new EnumerationList();
  searchText: string = "";

  get enumListItems(): EnumerationListItem[] {
    return this.enumList.EnumerationItems.filter(
      eli => eli.Deleted === false
    ).sort((a, b) => {
      if (a.SortOrder > b.SortOrder) return 1;
      else if (a.SortOrder < b.SortOrder) return -1;
      else return 0;
    });
  }

  mounted() {
    this.apiService
      .All([
        this.apiService.GetEnumerations(),
        this.apiService.GetEnumerationLists()
      ])
      .then(resps => {
        this.enumerations = resps[0].Value;
        this.existingSystemLists = resps[1].Value.map((el: EnumerationList) => {
          return el.SystemName;
        });

        if (this.$route.query.id) {
          let id = this.$route.query.id;
          this.apiService.GetEnumerationList(id as string).then(resp => {
            if (resp.Success) {
              this.enumList = resp.Value;

              if (this.enumList.SystemName) {
                this.existingSystemLists.splice(
                  this.existingSystemLists.indexOf(this.enumList.SystemName),
                  1
                );
              }

              //set default sort order
              let nonZero:
                | EnumerationListItem
                | undefined = this.enumList.EnumerationItems.find(
                x => x.SortOrder !== 0
              );

              if (!nonZero) {
                for (let idx in this.enumList.EnumerationItems) {
                  this.enumList.EnumerationItems[idx].SortOrder = parseInt(idx);
                }
              }
            }
          });
        }
      });
  }

  onAddEnum(item: Enumeration) {
    let elItem: EnumerationListItem = new EnumerationListItem();
    elItem.EnumerationId = item.Id;
    elItem.Enumeration = item;
    elItem.SortOrder = this.enumList.EnumerationItems.length;
    this.enumList.EnumerationItems.push(elItem);
  }

  onDeleteEnumItem(item: EnumerationListItem) {
    item.Deleted = true;

    //shift items
    this.enumList.EnumerationItems.filter(x => !x.Deleted).forEach(eli => {
      if (eli.SortOrder > item.SortOrder) {
        --eli.SortOrder;
      }
    });

    if (item.Id === Guid.Empty) {
      let idx = this.enumList.EnumerationItems.indexOf(item);
      this.enumList.EnumerationItems.splice(idx, 1);
    }
  }

  onSaveClick() {
    if (
      (this.$refs.form as any).validate() &&
      (this.$refs.itemsForm as any).validate()
    ) {
      if (this.enumList.Id === Guid.Empty) this.save();
      else this.update();
    }
  }

  save() {
    this.apiService.CreateEnumerationList(this.enumList).then(resp => {
      if (resp.Success) {
        this.$router.push("/enumerations/lists");
      }
    });
  }

  update() {
    this.apiService.UpdateEnumerationList(this.enumList).then(resp => {
      if (resp.Success) {
        this.$router.push("/enumerations/lists");
      }
    });
  }

  moveItem(item: EnumerationListItem, move: number) {
    let otherItem: EnumerationListItem | undefined = this.enumListItems.find(
      x => x.SortOrder === item.SortOrder + move
    );

    if (otherItem) {
      otherItem.SortOrder -= move;
      item.SortOrder += move;
    }
  }

  setName() {
    if (!this.enumList.Name) {
      this.enumList.Name = this.enumList.SystemName || "";
    }
  }
}
