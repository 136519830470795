import LocalizationLocale from "@/types/Localization/LocalizationLocale";
import LocalizationKey from "@/types/Localization/LocalizationKey";
import UseCaseResponse from "@/types/UseCaseResponse";
import axios from "axios";
import BaseApiService from "./BaseApiService";

export default class LocalizationApiService extends BaseApiService {
  async GetLocalizationLocales(): Promise<
    UseCaseResponse<LocalizationLocale[]>
  > {
    await this.defineHeader();
    return axios
      .get(this.url + "/api/vms/Localization/Locales")
      .then(resp => resp.data as UseCaseResponse<LocalizationLocale[]>);
  }

  async GetLocalizationKeys(): Promise<UseCaseResponse<LocalizationKey[]>> {
    await this.defineHeader();
    return axios
      .get(this.url + "/api/vms/Localization")
      .then(resp => resp.data as UseCaseResponse<LocalizationKey[]>);
  }

  async GetLocalizationKeyById(
    id: string
  ): Promise<UseCaseResponse<LocalizationKey>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Localization/${id}`)
      .then(resp => resp.data as UseCaseResponse<LocalizationKey>);
  }

  async UpdateLocalizationKey(
    key: LocalizationKey
  ): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .put(`${this.url}/api/vms/Localization/${key.Id}`, key)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async CreateLocalizationKey(
    key: LocalizationKey
  ): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    return axios
      .post(this.url + "/api/vms/Localization", key)
      .then(resp => resp.data as UseCaseResponse<string>);
  }

  async DeleteLocalizationKey(
    key: LocalizationKey
  ): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .delete(`${this.url}/api/vms/Localization/${key.Id}`)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async ProcessLocalizationFile(
    group_name: string,
    localeObject: object
  ): Promise<UseCaseResponse<object>> {
    await this.defineHeader();
    return axios
      .post(
        `${this.url}/api/localizations/Localization/${group_name}`,
        localeObject
      )
      .then(resp => resp.data as UseCaseResponse<object>);
  }

  async PatchLocalizationFile(
    group_name: string,
    localeObject: object
  ): Promise<UseCaseResponse<number>> {
    await this.defineHeader();
    return axios
      .patch(
        `${this.url}/api/localizations/Localization/${group_name}`,
        localeObject
      )
      .then(resp => resp.data as UseCaseResponse<number>);
  }
}
