
import Enumeration from "@/types/Enumeration/Enumeration";
import LocalizationString from "@/types/Localization/LocalizationString";
import VolunteerProfile from "@/types/Profile/VolunteerProfile";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class VolunteerProfileTable extends Vue {
  @Prop(Object) value: VolunteerProfile | undefined;

  profile: VolunteerProfile = this.value || new VolunteerProfile();

  @Watch("value")
  onValueChanged() {
    this.profile = this.value || new VolunteerProfile();
  }

  localizeEnum(e: Enumeration): string | undefined {
    if (!e) {
      return "";
    } else {
      let ls:
        | LocalizationString
        | undefined = e.LocalizationKey.LocalizationStrings.find(
        x => x.Locale.Locale === this.$i18n.locale
      );
      return ls ? ls.Text : "";
    }
  }
}
