import EducatorAuxData from "@/types/Educator/EducatorAuxData";
import UseCaseResponse from "@/types/UseCaseResponse";
import BaseApiService from "./BaseApiService";
import axios from "axios";

export default class EducatorAuxDataApiService extends BaseApiService {
  async CreateEducatorAuxData(
    data: EducatorAuxData
  ): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/EducatorAuxData`, data)
      .then(resp => resp.data as UseCaseResponse<string>);
  }

  async GetEducatorAuxData(): Promise<UseCaseResponse<EducatorAuxData[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/EducatorAuxData`)
      .then(resp => resp.data as UseCaseResponse<EducatorAuxData[]>);
  }

  async GetEducatorAuxDataBySiteId(
    siteId: string
  ): Promise<UseCaseResponse<EducatorAuxData[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/EducatorAuxData?siteId=${siteId}`)
      .then(resp => resp.data as UseCaseResponse<EducatorAuxData[]>);
  }

  async SearchEducatorAuxData(
    search: string
  ): Promise<UseCaseResponse<EducatorAuxData[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/EducatorAuxData?search=${search}`)
      .then(resp => resp.data as UseCaseResponse<EducatorAuxData[]>);
  }

  async GetEducatorAuxDataById(
    id: string
  ): Promise<UseCaseResponse<EducatorAuxData>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/EducatorAuxData/${id}`)
      .then(resp => resp.data as UseCaseResponse<EducatorAuxData>);
  }

  async UpdateEducatorAuxData(
    data: EducatorAuxData
  ): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .put(`${this.url}/api/vms/EducatorAuxData/${data.Id}`, data)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async PatchEducatorAuxData(
    patches: EducatorAuxData[]
  ): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .patch(`${this.url}/api/vms/EducatorAuxData`, patches)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }
}
