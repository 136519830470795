
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Enumeration from "../../types/Enumeration/Enumeration";
import EnumerationApiService from "../../services/EnumerationApiService";
import { TranslateResult } from "vue-i18n";
import ConfirmButton from "../../components/ConfirmButton.vue";
import VMSTextField from "@/components/fields/VMSTextField.vue";

@Component({
  components: {
    ConfirmButton,
    VMSTextField
  }
})
export default class EnumerationsView extends Vue {
  apiService: EnumerationApiService = new EnumerationApiService();
  headers: Object[] = [
    {
      text: this.$root.$t("Text"),
      align: "left",
      value: "Text"
    },
    {
      text: this.$root.$t("Description"),
      align: "left",
      value: "Description"
    },
    {
      text: this.$root.$t("Value"),
      align: "left",
      value: "Value"
    },
    {
      text: "",
      align: "right",
      value: "Actions"
    }
  ];

  enumerations: Enumeration[] = [];
  lblSearch: TranslateResult = this.$root.$t("Search");
  searchText: string = "";

  mounted() {
    this.apiService.GetEnumerations().then(resp => {
      this.enumerations = resp.Value;
    });
  }

  onDeleteConfirmed(item: Enumeration) {
    this.apiService.DeleteEnumeration(item).then(resp => {
      if (resp.Value) {
        //remove from ui
        this.enumerations.splice(this.enumerations.indexOf(item), 1);
      } else {
        console.log("Failed to delete...");
      }
    });
  }

  onCreateClick() {
    this.$router.push("/enumerations/create");
  }
}
