
import Vue from "vue";
import Component from "vue-class-component";
import OutreachRequestApiService from "@/services/OutreachRequestApiService";
import OutreachRequest, {
  OutreachType
} from "@/types/OutreachRequest/OutreachRequest";
import OutreachRequestClass from "@/types/OutreachRequest/OutreachRequestClass";
import OutreachRequestClassGrade from "@/types/OutreachRequest/OutreachRequestClassGrade";
import { Prop } from "vue-property-decorator";
import SiteSelect from "@/components/SiteSelect.vue";
import VMSSelect from "@/components/fields/VMSSelect.vue";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import SchoolLookup from "@/components/school/SchoolLookup.vue";
import YouthDemographicsLog from "@/components/youthdemographics/YouthDemographicsLog.vue";
import Guid from "@/types/Guid";
import GetOrganizationResponse from "@/types/Organization/GetOrganizationResponse";
import OrganizationApiService from "@/services/OrganizationApiService";
import GetOrgEnumResponse from "@/types/Organization/GetOrgEnumResponse";
import VMSTextArea from "@/components/fields/VMSTextArea.vue";
import OutreachRequestNote from "@/types/OutreachRequest/OutreachRequestNote";
import ConfirmButton from "@/components/ConfirmButton.vue";
import OutreachRequestClassList from "@/components/outreachrequest/OutreachClassList.vue";
import OutreachRequestSite from "@/types/OutreachRequest/OutreachRequestSite";
import EnumerationSelect from "@/components/EnumerationSelect.vue";
import EnumerationList from "@/types/Enumeration/EnumerationList";
import EnumerationApiService from "@/services/EnumerationApiService";

@Component({
  components: {
    SiteSelect,
    VMSSelect,
    VMSTextField,
    SchoolLookup,
    YouthDemographicsLog,
    VMSTextArea,
    ConfirmButton,
    OutreachRequestClassList,
    EnumerationSelect
  }
})
export default class OutreachRequestCreate extends Vue {
  @Prop(String) siteId: string | undefined;
  @Prop(String) urlSite: string | undefined;

  outreachApiService: OutreachRequestApiService = new OutreachRequestApiService();
  enumApiService: EnumerationApiService = new EnumerationApiService();

  new_note_text: string = "";
  outreach_request: OutreachRequest = this.newRequest();

  selected_school: GetOrganizationResponse | null = null;
  orgApiService: OrganizationApiService = new OrganizationApiService();
  org_regions: GetOrgEnumResponse[] = [];

  has_school: boolean = true;

  languages: EnumerationList = new EnumerationList();

  get outreach_type_options(): object[] {
    return [
      {
        text: this.$root.$t("OutreachRequest.Type.InPerson"),
        value: OutreachType.InPerson
      },
      {
        text: this.$root.$t("OutreachRequest.Type.Virtual"),
        value: OutreachType.Virtual
      },
      {
        text: this.$root.$t("OutreachRequest.Type.Either"),
        value: OutreachType.Either
      }
    ];
  }

  get outreach_notes(): OutreachRequestNote[] {
    return this.outreach_request.Notes.filter(n => !n.Deleted).sort((a, b) => {
      const d1 = new Date(a.DisplayDate);
      const d2 = new Date(b.DisplayDate);
      if (d1 < d2) return 1;
      else if (d1 > d2) return -1;
      else return 0;
    });
  }

  get other_language_id(): string {
    const other_enum = this.languages.EnumerationItems.find(
      x => x.Enumeration && x.Enumeration.Value === "Other"
    );
    return other_enum ? other_enum.EnumerationId : "";
  }

  mounted() {
    if (this.$route.query.id) {
      let id: string = this.$route.query.id as string;
      this.outreachApiService.GetById(id).then(resp => {
        if (resp.Success) this.outreach_request = resp.Value;
        else this.outreach_request = this.newRequest();

        this.has_school =
          this.outreach_request.OrganizationId != null &&
          this.outreach_request.OrganizationId != "";
      });
    }

    this.orgApiService.GetEnumerationList("Region").then(resp => {
      if (resp.Success) {
        this.org_regions = resp.Value;
      }
    });

    this.enumApiService
      .GetEnumerationListsBySystemName(["Languages"])
      .then(resp => {
        if (resp.Success) {
          this.languages = resp.Value[0] || new EnumerationList();
        }
      });
  }

  newRequest() {
    const ret = new OutreachRequest();
    const or_class = new OutreachRequestClass();
    or_class.Grades = [new OutreachRequestClassGrade()];
    ret.Classes = [or_class];

    const site_record: OutreachRequestSite = new OutreachRequestSite();
    site_record.SiteId = this.siteId || "";
    ret.SiteRecords = [site_record];
    return ret;
  }

  onSchoolSelected(s: GetOrganizationResponse) {
    this.selected_school = s;
  }

  onSchoolInput(id: string | null) {
    if (!id) {
      this.selected_school = null;
    }
  }

  onHasSchoolChanged() {
    console.log("has school changed");
    if (this.has_school) {
      this.outreach_request.NoOrgDetails = "";
      this.outreach_request.NoOrgPostalCode = "";
    } else {
      this.outreach_request.OrganizationId = "";
      this.selected_school = null;
    }
  }

  onUseSchoolAddressClicked() {
    if (this.selected_school) {
      this.outreach_request.EventAddress =
        this.selected_school.PhysicalAddress ||
        this.selected_school.Address ||
        "";
      this.outreach_request.EventCity = this.selected_school.City || "";
      this.outreach_request.EventPostalCode =
        this.selected_school.PhysicalPostalCode ||
        this.selected_school.PostalCode ||
        "";

      if (this.selected_school.RegionId) {
        const region: any = this.org_regions.find(
          r => r.Id == this.selected_school!.RegionId
        );
        if (region) {
          this.outreach_request.EventProvince = region.Text || "";
        }
      }
    }
  }

  onSaveClicked() {
    if ((this.$refs.orForm as any).validate()) {
      if (!this.outreach_request.Id || this.outreach_request.Id == Guid.Empty) {
        this.outreachApiService.Create(this.outreach_request).then(resp => {
          if (resp.Success) {
            this.$router.go(-1);
          }
        });
      } else {
        this.outreachApiService.Update(this.outreach_request).then(resp => {
          if (resp.Success) {
            this.$router.go(-1);
          }
        });
      }
    }
  }

  onDeleteNoteConfirmed(note: OutreachRequestNote) {
    const idx: number = this.outreach_request.Notes.indexOf(note);
    if (idx >= 0) {
      if (note.Id == Guid.Empty) {
        this.outreach_request.Notes.splice(idx, 1);
      } else {
        note.Deleted = true;
      }
    }
  }

  onNoteAdd() {
    if (this.new_note_text.length > 0) {
      const nn: OutreachRequestNote = new OutreachRequestNote();
      nn.Text = this.new_note_text;
      nn.DisplayDate = new Date().toString();
      this.outreach_request.Notes.push(nn);
      this.new_note_text = "";
    }
  }
}
