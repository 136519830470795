
import EnumerationList from "@/types/Enumeration/EnumerationList";
import EnumerationListItem from "@/types/Enumeration/EnumerationListItem";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import VMSSelect from "@/components/fields/VMSSelect.vue";

@Component({
  components: {
    VMSSelect
  }
})
export default class EnumerationSelect extends Vue {
  @Prop(Object) list: EnumerationList | undefined;
  @Prop() value: string[] | string | undefined;
  @Prop(String) id: String | undefined;
  @Prop(String) label: String | undefined;
  @Prop(String) hint: String | undefined;
  @Prop(String) placeholder: String | undefined;
  @Prop(Array) rules: Array<Function> | undefined;
  @Prop(Boolean) disabled: boolean | undefined;
  @Prop(Boolean) multiple: boolean | undefined;

  selected: string[] = this.valueToSelected();
  enumList: EnumerationList = this.list
    ? JSON.parse(JSON.stringify(this.list))
    : new EnumerationList();

  valueToSelected(): string[] {
    let ret: string[] = [];
    if (this.value) {
      if (Array.isArray(this.value)) {
        ret = this.value;
      } else {
        ret = [this.value];
      }
    }

    return ret;
  }

  @Watch("list")
  onListChanged() {
    this.enumList = this.list
      ? JSON.parse(JSON.stringify(this.list))
      : new EnumerationList();
  }

  get Items(): object[] {
    return this.enumList.EnumerationItems.sort((a, b) => {
      if (a.SortOrder > b.SortOrder) return 1;
      else if (a.SortOrder < b.SortOrder) return -1;
      else return 0;
    }).map((eli: EnumerationListItem) => {
      return {
        text: this.$t(eli.Enumeration.LocalizationKeyId),
        value: eli.EnumerationId
      };
    });
  }

  @Watch("value")
  onPropValueChanged() {
    this.selected = this.valueToSelected();
    this.emitConditional();
  }

  get selectedItem(): string {
    return this.selected.length > 0 ? this.selected[0] : "";
  }

  set selectedItem(item: string) {
    this.selected = [];
    this.selected.push(item);
  }

  get selectedItems(): string[] | string {
    if (this.multiple) return this.selected;
    else return this.selectedItem;
  }

  set selectedItems(val: string[] | string) {
    if (!Array.isArray(val)) this.selectedItem = val;
    else this.selected = val;

    if (this.multiple) {
      this.$emit("input", this.selected);
    } else {
      this.$emit("input", this.selectedItem);
    }

    this.emitConditional();
  }

  get ConditionalTriggerIds(): string[] {
    if (
      !this.list ||
      !this.list.EnumerationItems ||
      this.list.EnumerationItems.length === 0
    ) {
      return [];
    } else {
      return this.list.EnumerationItems.filter(
        eli => eli.ConditionalTrigger
      ).map(eli => {
        return eli.EnumerationId;
      });
    }
  }

  emitConditional() {
    if (this.ConditionalTriggerIds) {
      let conditional: boolean = false;
      if (this.multiple) {
        conditional = this.ConditionalTriggerIds.some(x =>
          this.selected.includes(x)
        );
      } else {
        conditional = this.ConditionalTriggerIds.includes(this.selectedItem);
      }

      this.$emit("conditionaltrigger", conditional);
    }
  }
}
