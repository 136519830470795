
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import EnumerationList from "../../types/Enumeration/EnumerationList";
import EnumerationApiService from "../../services/EnumerationApiService";
import { TranslateResult } from "vue-i18n";
import ConfirmButton from "../../components/ConfirmButton.vue";
import VMSTextField from "@/components/fields/VMSTextField.vue";

@Component({
  components: {
    ConfirmButton,
    VMSTextField
  }
})
export default class EnumerationListsView extends Vue {
  //@Prop(Boolean) value: Boolean | undefined;
  apiService: EnumerationApiService = new EnumerationApiService();
  headers: object[] = [
    {
      text: this.$root.$t("Name"),
      align: "left",
      value: "Name"
    },
    {
      text: this.$root.$t("SystemName"),
      align: "left",
      value: "SystemName"
    },
    {
      text: "",
      align: "right",
      value: "Actions"
    }
  ];
  enumLists: EnumerationList[] = [];
  lblSearch: TranslateResult = this.$root.$t("Search");
  searchText: string = "";

  mounted() {
    this.getData();
  }

  getData() {
    this.apiService.GetEnumerationLists().then(resp => {
      if (resp.Success) {
        this.enumLists = resp.Value;
      }
    });
  }

  onDeleteConfirmed(item: EnumerationList) {
    let that = this;
    this.apiService.DeleteEnumerationList(item).then(resp => {
      if (resp.Success) {
        that.getData();
      }
    });
  }

  onEditItemClick(item: EnumerationList) {
    this.$router.push(`/enumerations/lists/update?id=${item.Id}`);
  }

  onCreateClick() {
    this.$router.push("/enumerations/lists/create");
  }
}
