
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import LocalizationKey from "../../types/Localization/LocalizationKey";
import LocalizationString from "../../types/Localization/LocalizationString";
import LocalizationLocale from "../../types/Localization/LocalizationLocale";
import LocalizationApiService from "../../services/LocalizationApiService";
import { TranslateResult, LocaleMessageObject } from "vue-i18n";
import Guid from "../../types/Guid";
import VMSTextField from "@/components/fields/VMSTextField.vue";

@Component({
  components: {
    VMSTextField
  }
})
export default class LocalizationCreate extends Vue {
  lblValue: TranslateResult = this.$root.$t("Value");
  lblUnique: TranslateResult = this.$root.$t("IsUnique");
  lblIsRequired: TranslateResult = this.$root.$t("IsRequired");

  apiService: LocalizationApiService = new LocalizationApiService();

  locales: LocalizationLocale[] = [];
  localeStrings: string[] = [];
  localizationKeys: LocalizationKey[] = [];
  key: LocalizationKey = new LocalizationKey();
  localeStringsMap: Map<string, string[]> = new Map<string, string[]>();
  errMsg: string = "";

  mounted() {
    var that = this;
    this.apiService
      .All([
        this.apiService.GetLocalizationLocales(),
        this.apiService.GetLocalizationKeys()
      ])
      .then(resps => {
        that.locales = resps[0].Value;
        that.localizationKeys = resps[1].Value;

        that.setStringMap();

        if (this.$route.query.Id) {
          let id: string = this.$route.query.Id as string;
          this.apiService.GetLocalizationKeyById(id).then(resp => {
            that.key = resp.Value;
            that.setLocaleStrings();
          });
        }
      });
  }

  setStringMap() {
    this.locales.forEach(locale => {
      this.localeStringsMap.set(locale.Id, []);
    });

    this.localizationKeys.forEach(lKey => {
      if (lKey.LocalizationStrings) {
        lKey.LocalizationStrings.forEach((lString: LocalizationString) => {
          let strings: string[] =
            this.localeStringsMap.get(lString.LocaleId) || [];
          strings.push(lString.Text);
        });
      }
    });
  }

  setLocaleStrings() {
    this.localeStrings = [];
    let strings: string[] = [];
    for (let idx in this.locales) {
      let lString:
        | LocalizationString
        | undefined = this.key.LocalizationStrings.find(
        x => x.LocaleId == this.locales[idx].Id
      );
      if (lString) strings[idx] = lString.Text;
      else strings[idx] = "";
    }

    this.localeStrings = strings;
  }

  isUnique(val: string, localeId: string): boolean {
    let found: LocalizationKey | undefined = this.localizationKeys.find(
      x =>
        x.LocalizationStrings.find(
          y => y.Text == val && y.LocaleId === localeId
        ) !== undefined && x.Id !== this.key.Id
    );

    return found === undefined;
  }

  onAddClicked() {
    this.errMsg = "";
    if (this.localeStrings.length > 0) {
      if (this.key.Id === Guid.Empty) {
        this.key.LocalizationStrings = [];
        for (let idx in this.localeStrings) {
          let lString: LocalizationString = new LocalizationString();
          lString.LocaleId = this.locales[idx].Id;
          lString.Locale = this.locales[idx];
          lString.Text = this.localeStrings[idx];
          this.key.LocalizationStrings.push(lString);
        }

        this.apiService
          .CreateLocalizationKey(this.key)
          .then(resp => {
            this.key.Id = resp.Value;
            this.$VmsLocalizations.Load();
            this.$router.push(`/localizations`);
          })
          .catch(err => {
            this.errMsg = err.response.data.Message;
          });
      } else {
        for (let idx in this.localeStrings) {
          let lString:
            | LocalizationString
            | undefined = this.key.LocalizationStrings.find(
            x => x.LocaleId == this.locales[idx].Id
          );
          if (lString) lString.Text = this.localeStrings[idx];
          else {
            let newString: LocalizationString = new LocalizationString();
            newString.Text = this.localeStrings[idx];
            newString.LocaleId = this.locales[idx].Id;
            newString.Locale = this.locales[idx];
            this.key.LocalizationStrings.push(newString);
          }
        }

        this.apiService
          .UpdateLocalizationKey(this.key)
          .then(resp => {
            this.$VmsLocalizations.Load();
            this.$router.push(`/localizations`);
          })
          .catch(err => {
            this.errMsg = err.response.data.Message;
          });
      }
    }
  }
}
