
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import LocalizationKey from "../../types/Localization/LocalizationKey";
import LocalizationLocale from "../../types/Localization/LocalizationLocale";
import LocalizationApiService from "../../services/LocalizationApiService";
import { TranslateResult } from "vue-i18n";
import ConfirmButton from "../../components/ConfirmButton.vue";
import DataObject from "../../types/DataObject";
import LocalizationString from "../../types/Localization/LocalizationString";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import AuthorizationService from "@/services/AuthorizationService";

//wrapper class for rendering / binding items in the data table
class DisplayItem {
  Id: string = "";
  DataItem: LocalizationKey = new LocalizationKey();
  Strings: DataObject = new DataObject();

  constructor(k: LocalizationKey) {
    this.Id = k.Id;
    this.DataItem = k;
    k.LocalizationStrings.forEach(ls => {
      this.Strings[ls.LocaleId] = ls.Text;
    });
  }
}

@Component({
  components: {
    ConfirmButton,
    VMSTextField
  }
})
export default class LocalizationList extends Vue {
  lblKey: TranslateResult = this.$root.$t("Key");
  lblSearch: TranslateResult = this.$root.$t("Search");

  apiService: LocalizationApiService = new LocalizationApiService();
  headers: Object[] = [];

  locales: LocalizationLocale[] = [];
  displayItems: DisplayItem[] = [];
  searchText: string = "";
  authService: AuthorizationService = AuthorizationService.getInstance();

  patchingLocales: boolean = false;
  get ElevatedUser(): boolean {
    return this.authService.hasRole(["localizer", "administrator"]);
  }

  ItemText(item: DisplayItem): string {
    let found:
      | LocalizationString
      | undefined = item.DataItem.LocalizationStrings.find(
      x => x.Locale.Locale === this.$i18n.locale
    );
    return found ? found.Text : "";
  }

  mounted() {
    var that = this;
    this.apiService.GetLocalizationLocales().then(resp => {
      that.locales = resp.Value;
      that.buildHeaders();
    });

    this.apiService.GetLocalizationKeys().then(resp => {
      let keys = resp.Value;
      that.displayItems = [];
      keys.forEach(k => {
        that.displayItems.push(new DisplayItem(k));
      });
    });
  }

  buildHeaders() {
    this.locales.forEach(element => {
      this.headers.push({
        text: element.Description,
        align: "left",
        value: `Strings.${element.Id}`
      });
    });

    this.headers.push({
      text: "",
      align: "left",
      value: "Actions"
    });
  }

  onEditItemClick(item: DisplayItem) {
    this.$router.push(`/localization/update?Id=${item.DataItem.Id}`);
  }

  onDeleteItemClick(item: DisplayItem) {
    this.apiService.DeleteLocalizationKey(item.DataItem).then(resp => {
      if (resp.Value === true) {
        this.displayItems.splice(this.displayItems.indexOf(item), 1);
      }
    });
  }

  onAddClicked() {
    this.$router.push(`/localization/create`);
  }

  onPatchLocaleFiles() {
    if (this.ElevatedUser) {
      console.log(`patching with file: `);
      console.log(this.$VmsLocaleFiles);
      this.patchingLocales = true;
      this.apiService
        .PatchLocalizationFile("vms", this.$VmsLocaleFiles)
        .then(resp => {
          if (resp.Success) {
            this.$ShowSnackbar(`Added ${resp.Value} new keys!`);
          }
        })
        .finally(() => {
          this.patchingLocales = false;
        });
    }
  }
}
