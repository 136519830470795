
import Vue from "vue";
import Component from "vue-class-component";
import Site, { SiteType } from "../../types/Site/Site";
import SiteApiService from "../../services/SiteApiService";
import { SiteRequestStatus } from "@/types/Site/SiteOutreachRequestStatus";
import EnumerationSelect from "@/components/EnumerationSelect.vue";
import VMSTextArea from "@/components/fields/VMSTextArea.vue";
import VMSSelect from "@/components/fields/VMSSelect.vue";

@Component({
  components: {
    EnumerationSelect,
    VMSTextArea,
    VMSSelect
  }
})
export default class SiteList extends Vue {
  siteApiService: SiteApiService = new SiteApiService();
  sites: Site[] = [];

  headers: Object[] = [
    {
      text: this.$root.$t("Name"),
      align: "left",
      value: "Name"
    },
    {
      text: this.$root.$t("OutreachRequestStatus"),
      align: "left",
      value: "LatestStatus.Status",
      sortable: false
    },
    {
      text: this.$root.$t("Type"),
      align: "left",
      value: "Type",
      sortable: false
    },
    {
      text: this.$root.$t("DateOpenedClosed"),
      align: "left",
      value: "LatestStatus.CreatedOn",
      sortable: false
    },
    {
      text: "",
      align: "right",
      value: "Actions"
    }
  ];

  mounted() {
    this.getData();
  }

  getStatusString(s: number): string {
    return this.$t(`SiteOutreachStatus.${SiteRequestStatus[s]}`).toString();
  }

  getSiteTypeString(t: number): string {
    return SiteType[t];
  }

  getData() {
    this.siteApiService.GetSites().then(resp => {
      this.sites = resp.Value;
    });
  }

  onAddClicked() {
    this.$router.push("/sites/create");
  }

  onEditClicked(item: Site) {
    this.$router.push(`/sites/update?id=${item.Id}`);
  }

  onUpdateRequestStatusClicked(item: Site) {
    this.$router.push(`site/${item.Id}/intake`);
  }
}
