import BaseType from "../BaseType";
import SiteOutreachRequestStatus from "./SiteOutreachRequestStatus";

export enum SiteType {
  Regular = 0,
  Professional = 1,
  National = 2
}

export default class Site extends BaseType {
  Name: string = "";
  DriveURL: string = "";
  RegistrationConfig: string | null = null;
  EnglishHandbookURL: string = "";
  FrenchHandbookURL: string = "";
  OrganizationId: string = "";
  Type: SiteType = SiteType.Regular;
  Status: SiteOutreachRequestStatus[] = [];
  LatestStatus: SiteOutreachRequestStatus = new SiteOutreachRequestStatus();
}
