
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import EmailApiService from "../../services/EmailApiService";
import Email, { EmailType } from "../../types/Email/Email";
import EmailLocalization from "../../types/Email/EmailLocalization";
import ConfirmButton from "../../components/ConfirmButton.vue";
import Guid from "../../types/Guid";
import { TranslateResult } from "vue-i18n";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import VMSTextArea from "@/components/fields/VMSTextArea.vue";
import VMSSelect from "@/components/fields/VMSSelect.vue";

@Component({
  components: {
    ConfirmButton,
    VMSTextField,
    VMSTextArea,
    VMSSelect
  }
})
export default class EmailCreate extends Vue {
  //@Prop(Boolean) value: Boolean | undefined;
  email: Email = new Email();
  editLocalization: EmailLocalization | null = null;
  emailApiService: EmailApiService = new EmailApiService();
  localizationDialog: boolean = false;
  localizationLocale: string = "en";
  localizationSubject: string = "";
  localizationBody: string = "";
  editId: string = "";
  email_type: EmailType | null = null;

  locales: object[] = [
    {
      text: this.$root.$t("English"),
      value: "en"
    },
    {
      text: this.$root.$t("French"),
      value: "fr"
    },
    {
      text: this.$root.$t("EnglishAndFrench"),
      value: "enfr"
    }
  ];

  headers: object[] = [
    {
      text: this.$root.$t("Locale"),
      align: "left",
      value: "Locale"
    },
    {
      text: this.$root.$t("Subject"),
      align: "left",
      value: "Subject"
    },
    {
      text: this.$root.$t("Body"),
      align: "left",
      value: "Body"
    },
    {
      text: "",
      align: "right",
      value: "Actions"
    }
  ];

  get EmailLocales(): EmailLocalization[] {
    return this.email.Localizations.filter(x => x.Deleted === false);
  }

  get EmailCreateEditHeader(): TranslateResult {
    if (this.editId) return this.$t("EmailUpdate");
    else return this.$t("EmailCreate");
  }

  get TemplateParameters(): string[] {
    let ret: string[] = [];

    switch (this.email.EmailType) {
      case EmailType.SiteRegistration:
        ret = ["{subject_name}hint", "{subject_id}hint"];
        break;
      case EmailType.SiteRegistrationNotification:
        ret = [
          "{subject_email}hint",
          "{subject_firstname}hint",
          "{subject_lastname}hint",
          "{profile_heardaboutLTS}hint",
          "{profile_PRC}hint",
          "{profile_over18}hint",
          "{registration_querystring}hint",
          "{site_name}hint"
        ];
        break;
      case EmailType.RegistrationApproval:
        ret = ["{subject_name}hint", "{site_name}hint"];
        break;
      case EmailType.VolunteerAddedToActivity:
        ret = [
          "{subject_firstname}hint",
          "{workshop_name}hint",
          "{workshop_date}hint",
          "{workshop_time}hint"
        ];
        break;
      case EmailType.ActivityEvaluationRequest:
        ret = [
          "{workshop_id}hint",
          "{workshop_name}hint",
          "{workshop_date}hint",
          "{workshop_time}hint",
          "{educator_email}hint",
          "{submit_date}hint",
          "{submit_time}hint"
        ];
        break;
      case EmailType.KitDamaged:
        ret = [
          "{subject_name}hint",
          "{subject_email}hint",
          "{kit_summary_en}hint",
          "{kit_summary_fr}hint",
          "{workshop_name}hint",
          "{workshop_id}hint",
          "{workshop_date}hint",
          "{workshop_time}hint"
        ];
        break;
      case EmailType.NewOutreachRequestToOpenSiteRequestor:
      case EmailType.NewOutreachRequestToWaitlistedSiteRequestor:
      case EmailType.NewOutreachRequestToOpenSite:
      case EmailType.NewOutreachRequestToNational:
      case EmailType.OutreachRequestSiteUpdated:
        ret = [
          "{outreach_id}hint",
          "{contact_first_name}hint",
          "{contact_last_name}hint",
          "{contact_email}hint",
          "{request_type_en}hint",
          "{request_type_fr}hint",
          "{preferred_date_time}hint",
          "{site}hint",
          "{requested_site}hint",
          "{latest_note}hint"
        ];
        break;
      case EmailType.OutreachRequestDeclinedRequestor:
      case EmailType.OutreachRequestAcceptedRequestor:
        ret = [
          "{contact_first_name}hint",
          "{contact_last_name}hint",
          "{contact_email}hint",
          "{request_type_en}hint",
          "{request_type_fr}hint",
          "{preferred_date_time}hint",
          "{site}hint",
          "{requested_site}hint",
          "{latest_note}hint",
          "{note}hint"
        ];
        break;
      case EmailType.OutreachRequestIntakeClosed: //13
        ret = ["{public_comment}hint"];
        break;
    }

    return ret;
  }

  get emailTypes(): object[] {
    var ret = [];
    for (const t in EmailType) {
      const num_val = Number.parseInt(t);
      if (!Number.isNaN(num_val)) {
        ret.push({
          text: this.$root.$t(`EmailType.${EmailType[t]}`),
          value: num_val
        });
      }
    }

    return ret;
  }

  mounted() {
    if (this.$route.query.id) {
      this.editId = this.$route.query.id as string;
      this.emailApiService.GetEmailById(this.editId).then(resp => {
        this.email = resp.Value;
        this.email_type = this.email.EmailType;
      });
    }
  }

  getLocaleName(locale: string): string {
    let obj: any = this.locales.find((x: any) => x.value === locale);
    if (obj) return obj.text;
    else return "";
  }

  onSaveClick() {
    if ((this.$refs.emailForm as any).validate()) {
      if (this.email.Id === Guid.Empty) {
        //save
        this.emailApiService.CreateEmail(this.email).then(resp => {
          this.$router.push(`/emails`);
        });
      } else {
        //update
        this.emailApiService.UpdateEmail(this.email).then(resp => {
          this.$router.push(`/emails`);
        });
      }
    }
  }

  onAddLocalizationClick() {
    this.localizationLocale = "";
    this.editLocalization = null;
    this.localizationDialog = true;
  }

  onCancelClick() {
    this.$router.go(-1);
  }

  onSaveLocalizationClick() {
    if ((this.$refs.localizationForm as any).validate()) {
      let loc: EmailLocalization = new EmailLocalization();
      if (this.editLocalization) {
        loc = this.editLocalization;
      }

      loc.EmailId = this.email.Id;
      loc.Locale = this.localizationLocale;
      loc.Subject = this.localizationSubject;
      loc.Body = this.localizationBody;

      if (!this.email.Localizations.includes(loc)) {
        this.email.Localizations.push(loc);
      }

      this.localizationDialog = false;
      this.localizationLocale = "en";
      this.localizationSubject = "";
      this.localizationBody = "";
    }
  }

  onCancelLocalizationClick() {
    this.editLocalization = null;
    this.localizationDialog = false;
  }

  onEditLocalization(item: EmailLocalization) {
    this.editLocalization = item;
    this.localizationLocale = this.editLocalization.Locale;
    this.localizationSubject = this.editLocalization.Subject;
    this.localizationBody = this.editLocalization.Body;
    this.localizationDialog = true;
  }

  onDeleteLocalization(item: EmailLocalization) {
    item.Deleted = true;
  }
}
