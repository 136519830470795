import LocalizationKey from "./Localization/LocalizationKey";
import LocalizationApiService from "@/services/LocalizationApiService";
import LocalizationLocale from "./Localization/LocalizationLocale";
import VueI18n, { LocaleMessageObject } from "vue-i18n";
import DataObject from "./DataObject";
import LocalizationString from "./Localization/LocalizationString";

export default class VmsLocalizations {
  private _keys: LocalizationKey[] = [];
  private _locales: LocalizationLocale[] = [];
  private _apiService: LocalizationApiService = new LocalizationApiService();
  private _i18n: VueI18n;

  get Keys(): LocalizationKey[] {
    return JSON.parse(JSON.stringify(this._keys));
  }

  get Locales(): LocalizationLocale[] {
    return JSON.parse(JSON.stringify(this._locales));
  }

  constructor(i18n: VueI18n) {
    this._i18n = i18n;
  }

  private mergeLocalizationKeys() {
    let localeObject: DataObject = new DataObject();

    this._locales.forEach((ll: LocalizationLocale) => {
      localeObject[ll.Locale] = new DataObject();
    });

    this._keys.forEach((lk: LocalizationKey) => {
      lk.LocalizationStrings.forEach((ls: LocalizationString) => {
        let lObj: DataObject = localeObject[ls.Locale.Locale];
        if (lObj) lObj[lk.Id] = ls.Text;
      });
    });

    for (let locale in localeObject) {
      this._i18n.mergeLocaleMessage(
        locale,
        localeObject[locale] as LocaleMessageObject
      );
    }
  }

  GetLocaleId(locale: string): string {
    let locLocale: LocalizationLocale =
      this._locales.find(x => x.Locale == locale) || new LocalizationLocale();
    return locLocale.Id;
  }

  Load() {
    return this._apiService
      .All([
        this._apiService.GetLocalizationLocales(),
        this._apiService.GetLocalizationKeys()
      ])
      .then(resps => {
        this._locales = resps[0].Value;
        this._keys = resps[1].Value;
        this.mergeLocalizationKeys();
      });
  }
}
