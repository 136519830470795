
import Vue from "vue";
import Component from "vue-class-component";
import SiteApiService from "../../services/SiteApiService";
import Site from "@/types/Site/Site";
import EnumerationApiService from "@/services/EnumerationApiService";
import EnumerationList from "@/types/Enumeration/EnumerationList";
import VMSSelect from "@/components/fields/VMSSelect.vue";
import VMSTextArea from "@/components/fields/VMSTextArea.vue";
import EnumerationSelect from "@/components/EnumerationSelect.vue";
import SiteOutreachRequestStatus, {
  SiteRequestStatus
} from "@/types/Site/SiteOutreachRequestStatus";
import EnumerationListItem from "@/types/Enumeration/EnumerationListItem";
import LocalizationString from "@/types/Localization/LocalizationString";

@Component({
  components: {
    VMSSelect,
    VMSTextArea,
    EnumerationSelect
  }
})
export default class SiteIntakeStatus extends Vue {
  site: Site = new Site();
  siteApiService: SiteApiService = new SiteApiService();

  enumApiService: EnumerationApiService = new EnumerationApiService();
  statusReasonsEnumList: EnumerationList = new EnumerationList();

  edit_status: SiteRequestStatus | null = null;
  edit_reason_id: string | null = null;
  edit_comment: string = "";

  get site_id(): string {
    return this.$route.params.siteId;
  }

  get outreach_status_options(): object[] {
    const ret = [
      {
        text: this.$root.$t("SiteOutreachStatus.Open"),
        value: SiteRequestStatus.Open
      },
      {
        text: this.$root.$t("SiteOutreachStatus.Waitlisted"),
        value: SiteRequestStatus.Waitlisted
      },
      {
        text: this.$root.$t("SiteOutreachStatus.Closed"),
        value: SiteRequestStatus.Closed
      }
    ];

    return ret.filter((o: any) => o.value !== this.site.LatestStatus.Status);
  }

  get closed_status(): SiteRequestStatus {
    return SiteRequestStatus.Closed;
  }

  get historic_headers(): object[] {
    return [
      {
        text: "Assigned",
        value: "CreatedOn"
      },
      {
        text: "Status",
        value: "Status"
      },
      {
        text: "Reason",
        value: "Reason"
      },
      {
        text: "Comment",
        value: "Comment"
      }
    ];
  }

  get status_records(): object[] {
    return this.site.Status.sort((a, b) => {
      let aco: Date = new Date(a.CreatedOn);
      let bco: Date = new Date(b.CreatedOn);
      if (aco < bco) return 1;
      else if (aco > bco) return -1;
      else return 0;
    }).map(s => {
      return {
        CreatedOn: s.CreatedOn.substring(0, 10),
        Status: this.$root.$t(
          `SiteOutreachStatus.${SiteRequestStatus[s.Status]}`
        ),
        Reason: s.Reason,
        Comment: s.Comment
      };
    });
  }

  get current_status_text(): string {
    const s = SiteRequestStatus[this.site.LatestStatus.Status];
    return this.$t(`SiteOutreachStatus.${s}`).toString();
  }

  mounted() {
    this.enumApiService
      .GetEnumerationListsBySystemName(["SiteOutreachRequestIntakeReasons"])
      .then(resp => {
        if (resp.Success) {
          this.statusReasonsEnumList = resp.Value[0];
        }
      });

    this.getData();
  }

  getData() {
    if (this.site_id) {
      this.siteApiService.GetSiteById(this.site_id).then(resp => {
        this.site = resp.Value;
      });
    }
  }

  getEnumerationText(enum_id: string): string {
    const enumeration: EnumerationListItem =
      this.statusReasonsEnumList.EnumerationItems.find(
        e => e.EnumerationId === enum_id
      ) || new EnumerationListItem();
    const ls =
      enumeration.Enumeration.LocalizationKey.LocalizationStrings.find(
        s => s.Locale.Locale === this.$i18n.locale
      ) || new LocalizationString();
    return ls.Text;
  }

  onStatusSaveClicked() {
    const frm = this.$refs.statusForm as any;
    if (frm.validate()) {
      const new_status: SiteOutreachRequestStatus = new SiteOutreachRequestStatus();
      new_status.SiteId = this.site.Id;
      new_status.Status = this.edit_status || SiteRequestStatus.Open;
      new_status.ReasonId = this.edit_reason_id;
      new_status.Comment = this.edit_comment;

      frm.reset();

      this.siteApiService
        .CreateSiteOutreachRequestStatus(new_status)
        .then(resp => {
          if (resp.Success) {
            this.getData();
            this.edit_status = null;
            this.edit_reason_id = null;
            this.edit_comment = "";
          }
        });
    }
  }
}
