
import EnumerationApiService from "@/services/EnumerationApiService";
import EnumerationList from "@/types/Enumeration/EnumerationList";
import Guid from "@/types/Guid";
import SiteRegistrationConfig from "@/types/SiteRegistration/SiteRegistrationConfig";
import Vue from "vue";
import Component from "vue-class-component";
import SiteApiService from "../../services/SiteApiService";
import Site, { SiteType } from "@/types/Site/Site";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import VMSSelect from "@/components/fields/VMSSelect.vue";
import SchoolLookup from "@/components/school/SchoolLookup.vue";
import SiteOutreachRequestStatus, {
  SiteRequestStatus
} from "@/types/Site/SiteOutreachRequestStatus";

@Component({
  components: {
    VMSTextField,
    VMSSelect,
    SchoolLookup
  }
})
export default class SiteCreate extends Vue {
  site: Site = new Site();
  siteApiService: SiteApiService = new SiteApiService();

  configRegistration: boolean = false;
  enumApiService: EnumerationApiService = new EnumerationApiService();
  enumLists: EnumerationList[] = [];
  config: SiteRegistrationConfig = new SiteRegistrationConfig();

  get siteTypeOptions(): object[] {
    return [
      {
        text: "Regular",
        value: SiteType.Regular
      },
      {
        text: "Professional",
        value: SiteType.Professional
      },
      {
        text: "National",
        value: SiteType.National
      }
    ];
  }

  mounted() {
    this.enumApiService.GetEnumerationLists().then(resp => {
      this.enumLists = resp.Value;
    });

    if (this.$route.query.id) {
      let siteId: string = this.$route.query.id as string;
      this.siteApiService.GetSiteById(siteId).then(resp => {
        this.site = resp.Value;
        if (this.site.RegistrationConfig) {
          this.config = JSON.parse(
            this.site.RegistrationConfig
          ) as SiteRegistrationConfig;
          this.configRegistration = true;
        }
      });
    }
  }

  onSaveClicked() {
    if (this.configRegistration) {
      this.site.RegistrationConfig = JSON.stringify(this.config);
    } else {
      this.site.RegistrationConfig = null;
    }

    if (this.site.Id === Guid.Empty) {
      let status: SiteOutreachRequestStatus = new SiteOutreachRequestStatus();
      status.SiteId = Guid.Empty;
      status.Status = SiteRequestStatus.Open;
      this.site.Status.push(status);
      this.siteApiService.CreateSite(this.site).then(resp => {
        if (resp.Success) this.$router.push("/sites");
      });
    } else {
      this.siteApiService.UpdateSite(this.site).then(resp => {
        if (resp.Success) this.$router.push(`/sites`);
      });
    }
  }
}
