
import AuthorizationService from "@/services/AuthorizationService";
import TrainingApiService from "@/services/TrainingApiService";
import LocalizationString from "@/types/Localization/LocalizationString";
import Training from "@/types/Training/Training";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import ConfirmButton from "../../components/ConfirmButton.vue";
import VMSTextField from "@/components/fields/VMSTextField.vue";

class TrainingWrapper {
  Training: Training = new Training();
  Name: string = "";
  Type: string = "";

  get CompletedDate(): Date {
    return new Date(this.Training.CompletedDate);
  }

  constructor(t: Training, type: string, name: string) {
    this.Training = t;
    this.Type = type;
    this.Name = name;
  }
}

@Component({
  components: {
    ConfirmButton,
    VMSTextField
  }
})
export default class TrainingList extends Vue {
  /* props: { siteId: s.Id, urlSite: urlSite } */
  @Prop(String) siteId: string | undefined;
  @Prop(String) urlSite: string | undefined;

  search: string = "";
  trainingWrappers: TrainingWrapper[] = [];
  trainingApiService: TrainingApiService = new TrainingApiService();
  siteHostedOnly: boolean = true;
  activeSeasonOnly: boolean = true;
  authService: AuthorizationService = AuthorizationService.getInstance();

  headers: object[] = [
    {
      text: this.$root.$t("Name"),
      align: "left",
      value: "Name"
    },
    {
      text: this.$root.$t("Type"),
      align: "left",
      value: "Type"
    },
    {
      text: this.$root.$t("Date"),
      align: "left",
      value: "CompletedDate",
      sort: this.$DateSort
    },
    {
      text: "",
      align: "right",
      value: "Actions"
    }
  ];

  get StaffAdmin(): boolean {
    return this.authService.hasRole(["Administrator", "Staff"]);
  }

  mounted() {
    this.getData();
  }

  getData() {
    this.trainingApiService
      .GetTrainingBySiteId(
        this.siteId || "",
        this.activeSeasonOnly,
        this.siteHostedOnly
      )
      .then(resp => {
        this.trainingWrappers = [];
        resp.Value.forEach((t: Training) => {
          let name: string = "";
          if (t.OtherTraining) {
            name = t.OtherTraining;
          } else if (t.TrainingDelivered) {
            let ls: LocalizationString =
              t.TrainingDelivered.LocalizationKey.LocalizationStrings.find(
                ls => ls.Locale.Locale == this.$i18n.locale
              ) || new LocalizationString();
            name = ls.Text;
          }

          this.trainingWrappers.push(
            new TrainingWrapper(t, this.$t(t.TrainingType.Name) as string, name)
          );
        });
      });
  }

  onFiltersChanged() {
    this.getData();
  }

  onEditItem(item: TrainingWrapper) {
    let typename: string = item.Training.TrainingType
      ? item.Training.TrainingType.Name
      : "";
    if (typename) {
      this.$router.push(
        `/${this.urlSite}/${typename}/Update?id=${item.Training.Id}`
      );
    }
  }

  onDeleteConfirmed(item: TrainingWrapper) {
    this.trainingApiService.DeleteTraining(item.Training.Id).then(resp => {
      if (resp.Success) {
        this.getData();
      }
    });
  }
}
