
import ProgramSupportApiService from "@/services/ProgramSupportApiService";
import ProgramSupport from "@/types/ProgramSupport/ProgramSupport";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import ConfirmButton from "../../components/ConfirmButton.vue";
import VMSTextField from "@/components/fields/VMSTextField.vue";

class DisplayItem {
  Item: ProgramSupport = new ProgramSupport();

  get DateCompleted(): Date {
    return new Date(this.Item.DateCompleted);
  }

  constructor(ps: ProgramSupport) {
    this.Item = ps;
  }
}

@Component({
  components: {
    ConfirmButton,
    VMSTextField
  }
})
export default class ProgramSupportList extends Vue {
  /* props: { siteId: s.Id, urlSite: urlSite } */
  @Prop(String) siteId: string | undefined;
  @Prop(String) urlSite: string | undefined;

  isActiveSeasonFilterValue: boolean = true;
  search: string = "";

  displayItems: DisplayItem[] = [];
  programSupportApiService: ProgramSupportApiService = new ProgramSupportApiService();

  headers: object[] = [
    {
      text: this.$root.$t("Name"),
      align: "left",
      value: "Item.Name"
    },
    {
      text: this.$root.$t("DateCompleted"),
      align: "left",
      value: "DateCompleted",
      sort: this.$DateSort
    },
    {
      text: "",
      align: "right",
      value: "Actions"
    }
  ];

  mounted() {
    this.getData();
  }

  onIsActiveSeasonFilterChanged() {
    this.getData();
  }

  getData() {
    this.displayItems = [];
    this.programSupportApiService
      .GetProgramSupportBySiteId(
        this.siteId || "",
        this.isActiveSeasonFilterValue
      )
      .then(resp => {
        let programSupport: ProgramSupport[] = resp.Value;
        this.displayItems = programSupport.map((ps: ProgramSupport) => {
          return new DisplayItem(ps);
        });
      });
  }

  onEditItem(item: DisplayItem) {
    this.$router.push(
      `/${this.urlSite}/ProgramSupport/Update?id=${item.Item.Id}`
    );
  }

  onDeleteConfirmed(item: DisplayItem) {
    this.programSupportApiService
      .DeleteProgramSupport(item.Item.Id)
      .then(resp => {
        if (resp.Success) {
          this.getData();
        }
      });
  }
}
