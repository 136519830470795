
import EducatorAuxDataApiService from "@/services/EducatorAuxDataApiService";
import OrganizationApiService from "@/services/OrganizationApiService";
import EducatorAuxData from "@/types/Educator/EducatorAuxData";
import GetOrganizationResponse from "@/types/Organization/GetOrganizationResponse";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import EmailDialog from "@/components/EmailDialog.vue";
import EducatorSiteItem from "@/types/Educator/EducatorSiteItem";
import EducatorSiteHeader from "@/types/Educator/EducatorSiteHeader";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import VMSSelect from "@/components/fields/VMSSelect.vue";

class EducatorWrapper {
  Data: EducatorAuxData = new EducatorAuxData();
  Organization: string = "";
  SiteItem: EducatorSiteItem = new EducatorSiteItem();

  getOrgNameFunc: Function | undefined;
  private orgnameRequested: boolean = false;

  get OrganizationName(): string {
    if (this.getOrgNameFunc && !this.orgnameRequested) {
      this.orgnameRequested = true;
      this.getOrgNameFunc(this);
    }

    return this.Organization;
  }

  constructor(
    data: EducatorAuxData,
    siteItem: EducatorSiteItem,
    nameFunc: Function
  ) {
    this.Data = data;
    this.getOrgNameFunc = nameFunc;
    this.SiteItem = siteItem;
  }
}

@Component({
  components: {
    EmailDialog,
    VMSTextField,
    VMSSelect
  }
})
export default class EducatorList extends Vue {
  @Prop(String) urlSite: string | undefined;
  @Prop(String) siteId: string | undefined;

  apiService: EducatorAuxDataApiService = new EducatorAuxDataApiService();
  orgApiService: OrganizationApiService = new OrganizationApiService();
  educatorWrappers: EducatorWrapper[] = [];
  selectedWrappers: EducatorWrapper[] = [];
  search: string = "";
  emailDialog: boolean = false;
  statusFilterValue: boolean | null = null;

  get statusFilterOptions(): object[] {
    return [
      {
        text: this.$root.$t("Active"),
        value: true
      },
      {
        text: this.$root.$t("Inactive"),
        value: false
      }
    ];
  }

  get headers(): object[] {
    return [
      {
        text: this.$root.$t("FirstName"),
        align: "left",
        value: "Data.FirstName"
      },
      {
        text: this.$root.$t("LastName"),
        align: "left",
        value: "Data.LastName"
      },
      {
        text: this.$root.$t("School"),
        align: "left",
        value: "OrganizationName"
      },
      {
        text: this.$root.$t("Email"),
        align: "left",
        value: "Data.Email"
      },
      {
        text: this.$root.$t("PhoneNumber"),
        align: "left",
        value: "Data.PhoneNumber"
      },
      {
        text: this.$root.$t("Status"),
        align: "left",
        value: "SiteItem.IsActive",
        filter: this.statusFilter
      },
      {
        text: "",
        align: "right",
        value: "Actions"
      }
    ];
  }

  get Emails(): string {
    return this.selectedWrappers
      .map((ew: EducatorWrapper) => {
        return ew.Data.Email;
      })
      .join(`; `);
  }

  get SiteId(): string {
    return this.siteId || "";
  }

  mounted() {
    this.getData();
  }

  getData() {
    this.apiService.GetEducatorAuxDataBySiteId(this.SiteId).then(resp => {
      this.educatorWrappers = resp.Value.map((ead: EducatorAuxData) => {
        let header: EducatorSiteHeader =
          ead.EducatorSiteHeader || new EducatorSiteHeader();
        let esi: EducatorSiteItem =
          header.Items.find(x => x.SiteId === this.SiteId) ||
          new EducatorSiteItem();
        return new EducatorWrapper(ead, esi, this.getOrganizationName);
      });
    });
  }

  getOrganizationName(wrapper: EducatorWrapper) {
    this.orgApiService
      .GetOrganizationById(wrapper.Data.OrganizationId)
      .then(resp => {
        wrapper.Organization = (resp.Value as GetOrganizationResponse).Name;
      });
  }

  toggleActive(active: boolean) {
    let patches: EducatorAuxData[] = this.selectedWrappers.map(x => {
      return x.Data;
    });

    patches.forEach((ead: EducatorAuxData) => {
      if (ead.EducatorSiteHeader) {
        let esi:
          | EducatorSiteItem
          | undefined = ead.EducatorSiteHeader.Items.find(
          x => x.SiteId === this.SiteId
        );
        if (esi) {
          esi.IsActive = active;
        }
      }
    });

    this.apiService.PatchEducatorAuxData(patches).then(resp => {
      if (resp.Success) {
        this.getData();
      }
    });
  }

  statusFilter(
    val: any,
    search: string | undefined,
    item: EducatorWrapper
  ): boolean {
    if (this.statusFilterValue === null) {
      return true;
    } else {
      return item.SiteItem.IsActive === this.statusFilterValue;
    }
  }

  onAddClicked() {
    this.$router.push(`/${this.urlSite}/Educator/Create`);
  }

  onEditItem(wrapper: EducatorWrapper) {
    this.$router.push(`/${this.urlSite}/Educator/Update?id=${wrapper.Data.Id}`);
  }
}
