
import Vue from "vue";
import Component from "vue-class-component";
import SecurityService from "./services/SecurityService";
import ConfigurationService from "./services/ConfigurationService";
import SubjectApiService from "./services/SubjectApiService";
import LocalizationApiService from "./services/LocalizationApiService";
import AuthorizationService from "./services/AuthorizationService";
import VmsLocalizations from "./types/VmsLocalizations";
import ApiVersionApiService from "./services/ApiVersionApiService";
import VmsDrawer from "./components/navigation/VmsDrawer.vue";
import VmsAppBar from "./components/navigation/VmsAppBar.vue";
import Guid from "./types/Guid";
import Axios from "axios";
import i18n from "./i18n";
import ApiRequestTracker from "./ApiRequestTracker";
import Site from "./types/Site/Site";

@Component({
  components: {
    VmsDrawer,
    VmsAppBar
  }
})
export default class App extends Vue {
  name: String = "App";
  securityService: SecurityService = SecurityService.getInstance();
  subjectService: SubjectApiService = new SubjectApiService();
  signedIn: boolean = false;
  // @ts-ignore
  version: string = process.env.VMS_VERSION as string;
  showVersion: boolean = ConfigurationService.getInstance().getShowVersionFlag();
  loaded: boolean = false;
  apiVersionService: ApiVersionApiService = new ApiVersionApiService();
  apiVersion: string = "";
  drawer: boolean = true;
  axiosErrorSnackbar: boolean = false;

  basicSnackbar: boolean = false;
  basicSBText: string = "";

  viewLoading: boolean = false;

  localizationApiService: LocalizationApiService = new LocalizationApiService();

  selected_site: any = {};

  get hideNav(): boolean {
    return this.$route.meta!.noAuth;
  }

  get ShowLoading(): boolean {
    return this.viewLoading || this.runningAxiosRequests > 0;
  }

  CurrentRoute(): string {
    return this.$router.currentRoute.fullPath;
  }

  UserId(): string {
    const auth_service = AuthorizationService.getInstance();
    return auth_service ? auth_service.UserId : "";
  }

  runningAxiosRequests: number = 0;

  mounted() {
    ApiRequestTracker.Init(
      ConfigurationService.getInstance().ApiRequestLogSeconds
    );

    let that = this;
    Axios.interceptors.response.use(
      function(response) {
        ApiRequestTracker.Complete(response);
        --that.runningAxiosRequests;

        return response;
      },
      function(error) {
        ApiRequestTracker.Complete(error);
        --that.runningAxiosRequests;

        if (error.code === "ERR_NETWORK" || !error.response.data.Success) {
          that.axiosErrorSnackbar = true;
        }

        return error;
      }
    );

    Axios.interceptors.request.use(function(c) {
      ApiRequestTracker.Add(c, that.UserId(), that.CurrentRoute());
      ++that.runningAxiosRequests;
      return c;
    });

    Vue.prototype.$ShowSnackbar = this.showSnackbar;
    Vue.prototype.$SetLoading = this.setLoading;
    Vue.prototype.$DateSort = this.dateSort;
    Vue.prototype.$ReloadDrawer = this.reloadDrawer;
    Vue.prototype.$ShowAxiosError = () => {
      this.axiosErrorSnackbar = true;
    };

    //so we can later post only items from the files
    //and exclude things from vms.localizations
    Vue.prototype.$VmsLocaleFiles = JSON.parse(JSON.stringify(i18n.messages));

    if (!this.$route.meta!.noAuth) {
      this.checkSignedIn();
    } else {
      this.loaded = true;
    }

    this.$router.afterEach((to, from) => {
      if (from.meta!.noAuth && !to.meta!.noAuth) {
        this.loaded = false;
      }

      if (!to.meta!.noAuth) {
        this.checkSignedIn();
      } else {
        this.loaded = true;
      }
    });
  }

  dateSort(a: Date, b: Date): number {
    if (a > b) return 1;
    else if (a < b) return -1;
    else return 0;
  }

  checkSignedIn() {
    console.log("checking signed in");
    if (!this.signedIn) {
      this.securityService.getSignedIn().then(
        (signIn: any) => {
          this.signedIn = signIn;

          if (this.signedIn) {
            this.securityService.getUser().then(u => {
              Vue.prototype.$VmsLocalizations = new VmsLocalizations(
                this.$i18n
              );

              this.apiVersionService
                .GetApiVersion()
                .then(resp => {
                  this.apiVersion = resp;
                  console.info(`API Version: ${this.apiVersion}`);
                  console.info(`VMS_UI Version: ${this.version}`);
                })
                .catch(ex => {
                  console.log(
                    `error getting api version from api at: ${this.apiVersionService.url}`
                  );
                  console.log(ex);
                });

              var p = (u as any).profile;
              var req = {
                SubjectId: p.sub,
                GivenName: p.given_name,
                FamilyName: p.family_name
              };

              this.subjectService
                .RefreshSubject(req)
                .then(resp => {
                  this.subjectService
                    .GetSubjectById(Guid.Empty)
                    .then(subResp => {
                      Vue.prototype.$VmsLoggedInSubjectId = subResp.Value.Id;
                      AuthorizationService.init(subResp.Value);
                      Promise.all([
                        this.processLocaleFiles(),
                        this.$VmsLocalizations.Load()
                      ]).finally(() => {
                        this.loaded = true;
                      });
                    });
                })
                .catch(ex => {
                  console.log(
                    `error refreshing subject with api at: ${this.subjectService.url}`
                  );
                  console.log(ex);
                });
            });
          }
        },
        (err: any) => {
          console.log("error in checksignedin");
          console.log(err);
        }
      );
    }
  }

  async processLocaleFiles(): Promise<boolean> {
    let authService: AuthorizationService = AuthorizationService.getInstance();
    let resp = await this.localizationApiService.ProcessLocalizationFile(
      "vms",
      this.$VmsLocaleFiles
    );

    if (resp.Success) {
      const localeObject: any = resp.Value;
      for (const locale in localeObject) {
        i18n.mergeLocaleMessage(locale, localeObject[locale]);
      }

      if (authService.hasRole(["localizer", "administrator"])) {
        this.buildDebugLocale();
      }
    }

    return resp.Success;
  }

  buildDebugLocale() {
    let dbgLocale: any = {};
    for (const key in (this.$VmsLocaleFiles as any)["en"]) {
      dbgLocale[key] = key;
    }

    i18n.mergeLocaleMessage("debug", dbgLocale);
  }

  showSnackbar(text: string) {
    this.basicSBText = text;
    this.basicSnackbar = true;
  }

  onBackClick() {
    const meta = this.$router.currentRoute.meta || {};
    if (meta.back && meta.back.to_route) {
      this.$router.push(meta.back.to_route);
    }
  }

  setLoading(loading: boolean) {
    this.viewLoading = loading;
  }

  reloadDrawer() {
    if (this.$refs.drawer) {
      (this.$refs.drawer as VmsDrawer).load();
    }
  }

  onSiteChanged(site: any) {
    this.selected_site = site;
  }
}
