
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import SubjectApiService from "../../services/SubjectApiService";
import SeasonApiService from "../../services/SeasonApiService";
import SiteApiService from "../../services/SiteApiService";
import AwardLocale from "../../types/Award/AwardLocale";
import SubjectAward from "../../types/Award/SubjectAward";
import Subject from "../../types/Subject/Subject";
import Season from "../../types/Season";
import Site from "../../types/Site/Site";
import DataObject from "@/types/DataObject";
import HistoricHours from "@/types/Historic/HistoricHours";

class VolunteerSeason {
  Season: string = "";
  ActivityCount: number = 0;
  Hours: number = 0;
  SupportHours: number = 0;
  SeasonId: string = "";
}

class TrainingRecord {
  Date: string = "";
  Name: string = "";
  Skills: string[] = [];
  Hours: DataObject = new DataObject();

  get SkillsArray(): string {
    return this.Skills.join(", ");
  }
}

@Component
export default class SubjectRecord extends Vue {
  subjectApiService: SubjectApiService = new SubjectApiService();
  seasonApiService: SeasonApiService = new SeasonApiService();
  siteApiService: SiteApiService = new SiteApiService();
  subject: Subject = new Subject();
  seasons: Season[] = [];
  sites: Site[] = [];
  volunteerSeasons: VolunteerSeason[] = [];
  trainingRecords: TrainingRecord[] = [];
  trainingFacilitated: TrainingRecord[] = [];
  historicHours: HistoricHours[] = [];

  seasonHeaders: object[] = [
    {
      text: this.$root.$t("Season"),
      value: "Season"
    },
    {
      text: this.$root.$t("Activities"),
      value: "ActivityCount"
    },
    {
      text: this.$root.$t("Hours"),
      value: "Hours"
    },
    {
      text: this.$root.$t("Program Support"),
      value: "SupportHours"
    }
  ];

  get DisplaySeasonHeaders(): object[] {
    let ret: object[] = [];
    Object.assign(ret, this.seasonHeaders);
    ret.push({
      text: "",
      value: "Actions"
    });
    return ret;
  }

  trainingHeaders: object[] = [
    {
      text: this.$root.$t("Training"),
      value: "Name"
    },
    {
      text: this.$root.$t("Date"),
      value: "Date"
    },
    {
      text: this.$root.$t("Skills"),
      value: "Skills"
    }
  ];

  trainingFacilitatedHeaders: object[] = [
    {
      text: this.$root.$t("Training"),
      value: "Name"
    },
    {
      text: this.$root.$t("Date"),
      value: "Date"
    }
  ];

  historicHoursHeaders: object[] = [
    {
      text: this.$root.$t("Year"),
      value: "Year"
    },
    {
      text: this.$root.$t("Preparation"),
      value: "PrepHours"
    },
    {
      text: this.$root.$t("Delivery"),
      value: "DeliveryHours"
    }
  ];

  get TodaysDate(): string {
    return new Date().toISOString().substr(0, 10);
  }

  get DistinctSiteRegistrations(): string[] {
    const ret: string[] = [];
    this.subject.SiteRegistrations.forEach(sr => {
      if (sr.Site && !ret.includes(sr.Site.Name)) {
        ret.push(sr.Site.Name);
      }
    });

    return ret;
  }

  getAwardName(sw: SubjectAward, locale: string): string {
    if (sw.Award) {
      const aw: AwardLocale =
        sw.Award.Locales.find(x => x.Locale === locale) || new AwardLocale();
      return aw.Name;
    } else {
      return sw.OtherTitle;
    }
  }

  mounted() {
    let subjectId: string = "";
    if (this.$route.query.subjectId) {
      subjectId = this.$route.query.subjectId as string;
    } else {
      subjectId = this.$VmsLoggedInSubjectId;
    }

    this.subjectApiService
      .All([
        this.seasonApiService.GetSeasons(),
        this.siteApiService.GetSites(),
        this.subjectApiService.GetVolunteerRecordBySubjectId(subjectId)
      ])
      .then((resps: any) => {
        this.seasons = resps[0].Value;
        this.sites = resps[1].Value;
        this.subject = resps[2].Value.Subject;
        this.volunteerSeasons = resps[2].Value.VolunteerSeasons;
        this.trainingRecords = resps[2].Value.TrainingRecords;
        this.trainingFacilitated = resps[2].Value.TrainingFacilitatedRecords;
        this.historicHours = resps[2].Value.HistoricHours;

        this.addHourHeaders();
      });
  }

  addHourHeaders() {
    let hourTypes: string[] = [];
    this.trainingRecords.forEach((tr: TrainingRecord) => {
      for (let ht in tr.Hours) {
        if (!hourTypes.includes(ht)) {
          hourTypes.push(ht);
        }
      }
    });

    hourTypes.forEach((type: string) => {
      this.trainingHeaders.push({
        text: this.$t(type),
        value: `Hours.${type}`
      });
    });

    hourTypes = [];
    this.trainingFacilitated.forEach((tr: TrainingRecord) => {
      for (let ht in tr.Hours) {
        if (!hourTypes.includes(ht)) {
          hourTypes.push(ht);
        }
      }
    });

    hourTypes.forEach((type: string) => {
      this.trainingFacilitatedHeaders.push({
        text: this.$t(type),
        value: `Hours.${type}`
      });
    });
  }

  onPrintClick() {
    window.print();
  }

  onDownloadCertificateClicked(item: any) {
    this.subjectApiService.DownloadCertificate(
      this.subject.Id,
      item.SeasonId,
      this.$i18n.locale
    );
  }
}
