
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Enumeration from "../../types/Enumeration/Enumeration";
import EnumerationApiService from "../../services/EnumerationApiService";
import { TranslateResult } from "vue-i18n";
import LocalizationSelector from "../../components/LocalizationSelector.vue";
import VMSTextField from "@/components/fields/VMSTextField.vue";

@Component({
  components: {
    LocalizationSelector,
    VMSTextField
  }
})
export default class FieldCreate extends Vue {
  lblText: TranslateResult = this.$root.$t("Text");
  lblDesc: TranslateResult = this.$root.$t("Description");
  lblVal: TranslateResult = this.$root.$t("Value");
  lblRequired: TranslateResult = this.$root.$t("IsRequired");

  apiService: EnumerationApiService = new EnumerationApiService();
  enumeration: Enumeration = new Enumeration();
  enumerationItems: Enumeration[] = [];

  onSaveClicked() {
    //casting to any disables the type checking, but we should probably do something better
    if ((this.$refs.form as any).validate()) {
      this.apiService.CreateEnumeration(this.enumeration).then(resp => {
        if (resp.Value) {
          this.$router.push(`/enumerations`);
        } else {
          //show error msg, also this check should actually check we got a valid guid
          console.log("error");
        }
      });
    }
  }
}
