var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('h1',[_vm._v(_vm._s(_vm.$t("EnumerationCreate")))]),_c('div',[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',[_c('v-form',{ref:"form"},[_c('LocalizationSelector',{attrs:{"label":_vm.lblText,"placeholder":_vm.$t('AutocompletePlaceholderText'),"rules":[
              () =>
                _vm.enumeration.LocalizationKeyId.length > 0 ||
                `${_vm.lblText} ${_vm.lblRequired}`
            ]},model:{value:(_vm.enumeration.LocalizationKeyId),callback:function ($$v) {_vm.$set(_vm.enumeration, "LocalizationKeyId", $$v)},expression:"enumeration.LocalizationKeyId"}}),_c('VMSTextField',{attrs:{"label":_vm.lblDesc,"rules":[
              () =>
                _vm.enumeration.Description.length > 0 ||
                `${_vm.lblDesc} ${_vm.lblRequired}`
            ]},model:{value:(_vm.enumeration.Description),callback:function ($$v) {_vm.$set(_vm.enumeration, "Description", $$v)},expression:"enumeration.Description"}}),_c('VMSTextField',{attrs:{"label":_vm.lblVal,"rules":[
              () => _vm.enumeration.Value.length > 0 || `${_vm.lblVal} ${_vm.lblRequired}`
            ]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onSaveClicked.apply(null, arguments)}},model:{value:(_vm.enumeration.Value),callback:function ($$v) {_vm.$set(_vm.enumeration, "Value", $$v)},expression:"enumeration.Value"}})],1),_c('v-btn',{staticClass:"white--text",attrs:{"color":"blue darken-4"},on:{"click":_vm.onSaveClicked}},[_vm._v(_vm._s(_vm.$t("Save")))])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }