
import Vue from "vue";
import Component from "vue-class-component";
import OutreachRequestApiService from "@/services/OutreachRequestApiService";
import { OutreachType } from "@/types/OutreachRequest/OutreachRequest";
import { Prop } from "vue-property-decorator";
import ConfirmButton from "@/components/ConfirmButton.vue";
import AuthorizationService from "@/services/AuthorizationService";
import OrganizationApiService from "@/services/OrganizationApiService";
import VMSSelect from "@/components/fields/VMSSelect.vue";
import SiteApiService from "@/services/SiteApiService";
import Site from "@/types/Site/Site";
import SiteOutreachRequestStatus, {
  SiteRequestStatus
} from "@/types/Site/SiteOutreachRequestStatus";
import { RequestStatus } from "@/types/OutreachRequest/OutreachRequestStatus";
import QueryOutreachRequestRequest from "@/types/OutreachRequest/QueryOutreachRequestRequest";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import SiteSelect from "@/components/SiteSelect.vue";
import VMSTextArea from "@/components/fields/VMSTextArea.vue";
import OutreachRequestSiteUpdateDialog from "@/components/outreachrequest/OutreachRequestSiteUpdateDialog.vue";
import OutreachRequestStatusUpdateDialog from "@/components/outreachrequest/OutreachRequestStatusUpdateDialog.vue";
import DatePicker from "@/components/DatePicker.vue";
import ReportApiService from "@/services/ReportApiService";
import { DataOptions } from "vuetify";

//dto from api
interface IRequest {
  Id: string;
  Created: string;
  SiteName: string;
  Status: number;
  Type: number;
  ContactName: string;
  SchoolName: string;
}

@Component({
  components: {
    ConfirmButton,
    VMSSelect,
    VMSTextField,
    SiteSelect,
    VMSTextArea,
    OutreachRequestSiteUpdateDialog,
    OutreachRequestStatusUpdateDialog,
    DatePicker
  }
})
export default class OutreachRequestList extends Vue {
  @Prop(String) siteId!: string | undefined;
  @Prop(String) urlSite: string | undefined;

  outreachApiService: OutreachRequestApiService = new OutreachRequestApiService();
  authService: AuthorizationService = AuthorizationService.getInstance();
  orgApiService: OrganizationApiService = new OrganizationApiService();
  siteApiService: SiteApiService = new SiteApiService();

  outreach_requests: IRequest[] = [];
  selected_requests: IRequest[] = [];

  status_dialog: boolean = false;
  edit_requests: IRequest[] = [];

  sites: Site[] = [];
  site_dialog: boolean = false;

  waitlist_dialog: boolean = false;
  waitlisted_toggle: boolean = false;

  delete_confirmation: boolean = false;
  delete_id: string = "";

  query: QueryOutreachRequestRequest = new QueryOutreachRequestRequest();
  items_per_page: number = 10;
  page_records: number = 0;
  total_records: number = 0;

  reportApiService: ReportApiService = new ReportApiService();
  report_dialog: boolean = false;
  report_params: any = {
    SiteId: this.siteId,
    BeginDate: null,
    EndDate: null
  };

  onOptionsUpdated(options: DataOptions) {
    this.query.updateFromTableOptions(options);
    this.getData();
  }

  get outreach_status_options(): any[] {
    return [
      {
        text: this.$root.$t("OutreachRequest.Status.Pending"),
        value: RequestStatus.Pending
      },
      {
        text: this.$root.$t("OutreachRequest.Status.Scheduled"),
        value: RequestStatus.Scheduled
      },
      {
        text: this.$root.$t("OutreachRequest.Status.Accepted"),
        value: RequestStatus.Accepted
      },
      {
        text: this.$root.$t("OutreachRequest.Status.Waitlisted"),
        value: RequestStatus.Waitlisted
      },
      {
        text: this.$root.$t("OutreachRequest.Status.Declined"),
        value: RequestStatus.Declined
      },
      {
        text: this.$root.$t("OutreachRequest.Status.Cancelled"),
        value: RequestStatus.Cancelled
      },
      {
        text: this.$root.$t("OutreachRequest.Status.Completed"),
        value: RequestStatus.Completed
      }
    ];
  }

  get headers(): Object[] {
    let ret: any[] = this.staff_admin
      ? [
          {
            text: this.$root.$t("OutreachRequest.Site"),
            align: "left",
            value: "SiteName"
          }
        ]
      : [];

    return ret.concat([
      {
        text: this.$root.$t("OutreachRequest.ContactName"),
        align: "left",
        value: "ContactName"
      },
      {
        text: this.$root.$t("OutreachRequest.SchoolName"),
        align: "left",
        value: "SchoolName",
        sortable: false
      },
      {
        text: this.$root.$t("OutreachRequest.Status"),
        align: "left",
        value: "Status"
      },
      {
        text: this.$root.$t("OutreachRequest.Type"),
        align: "left",
        value: "Type"
      },
      {
        text: this.$root.$t("OutreachRequest.RequestReceived"),
        align: "left",
        value: "Created"
      },
      {
        text: "",
        align: "right",
        value: "Actions"
      }
    ]);
  }

  get site_status(): SiteOutreachRequestStatus {
    const site = this.sites.find(s => s.Id === this.siteId) || new Site();
    return site.LatestStatus;
  }

  get closed_status(): SiteRequestStatus {
    return SiteRequestStatus.Closed;
  }

  get waitlisted_status(): SiteRequestStatus {
    return SiteRequestStatus.Waitlisted;
  }

  get staff_admin(): boolean {
    return this.authService.hasRole(["Administrator", "Staff"]);
  }

  mounted() {
    this.query.SiteId = this.siteId || "";
    this.query.StatusFilters = [
      RequestStatus.Pending,
      RequestStatus.Scheduled,
      RequestStatus.Accepted
    ];

    this.getSites();
  }

  getSites() {
    this.siteApiService.GetSites().then(resp => {
      if (resp.Success) {
        this.sites = resp.Value;
      }
    });
  }

  getSiteStatusText(status: number): string {
    return this.$t(
      `SiteOutreachStatus.${SiteRequestStatus[status]}`
    ).toString();
  }

  getRequestStatusText(status: number): string {
    return this.$t(
      `OutreachRequest.Status.${RequestStatus[status]}`
    ).toString();
  }

  getRequestTypeText(type: number): string {
    return this.$t(`OutreachRequest.Type.${OutreachType[type]}`).toString();
  }

  getData() {
    this.outreachApiService.Query(this.query).then(resp => {
      if (resp.Success) {
        this.outreach_requests = resp.Value;
        this.page_records = resp.PageRecords;
        this.total_records = resp.TotalRecords;
      }
    });
  }

  onAddClicked() {
    this.$router.push(`/${this.urlSite}/OutreachRequest/Create`);
  }

  onEditClicked(item: IRequest) {
    this.$router.push(`/${this.urlSite}/OutreachRequest/Update?id=${item.Id}`);
  }

  onDeleteClicked(item: IRequest) {
    this.delete_id = item.Id;
    this.delete_confirmation = true;
  }

  onDeleteConfirmed() {
    this.delete_confirmation = false;
    this.outreachApiService
      .Delete(this.delete_id)
      .then(resp => {
        if (resp.Success) {
          this.getData();
        }
      })
      .finally(() => {
        this.delete_id = "";
      });
  }

  onToggleWaitlist(wl: boolean) {
    this.waitlisted_toggle = wl;
    this.waitlist_dialog = true;
  }

  onUpdateSiteClicked(item: IRequest) {
    this.edit_requests = [item];
    this.site_dialog = true;
  }

  onUpdateSelectedSiteClicked() {
    this.edit_requests = JSON.parse(JSON.stringify(this.selected_requests));
    this.site_dialog = true;
  }

  onUpdateStatusClicked(item: IRequest) {
    this.edit_requests = [item];
    this.status_dialog = true;
  }

  onUpdateSelectedStatusClicked() {
    this.edit_requests = JSON.parse(JSON.stringify(this.selected_requests));
    this.status_dialog = true;
  }

  onUpdateSiteStatusConfirmed() {
    const status: SiteOutreachRequestStatus = new SiteOutreachRequestStatus();
    status.SiteId = this.siteId || "";

    status.Status = this.waitlisted_toggle
      ? SiteRequestStatus.Waitlisted
      : SiteRequestStatus.Open;

    this.siteApiService
      .CreateSiteOutreachRequestStatus(status)
      .then(resp => {
        if (resp.Success) {
          this.getSites();
        }
      })
      .finally(() => {
        this.waitlist_dialog = false;
      });
  }

  onViewHistoryClicked(item: IRequest) {
    this.$router.push(`OutreachRequest/${item.Id}/History`);
  }

  onSiteUpdateCancelled() {
    this.site_dialog = false;
  }

  onSiteUpdateComplete() {
    this.getData();
    this.site_dialog = false;
    this.selected_requests = [];
  }

  onStatusUpdateCancelled() {
    this.status_dialog = false;
  }

  onStatusUpdateComplete() {
    this.getData();
    this.selected_requests = [];
    this.status_dialog = false;
  }

  onExportClicked() {
    if ((this.$refs.reportForm as any).validate()) {
      this.reportApiService
        .RunReport("OutreachRequestExport", this.report_params)
        .then(resp => {
          if (!resp) {
            console.log(resp);
          }
        })
        .finally(() => {
          this.report_dialog = false;
        });
    }
  }
}
