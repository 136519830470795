import { DataOptions } from "vuetify";

export enum SortDirection {
  Ascending,
  Descending,
  None
}

export class PagedQueryRequest {
  Skip: number = 0;
  Take: number = 10;
  Sort: SortDirection = SortDirection.None;
  Search: string = "";
  SortColumn: string = "";

  updateFromTableOptions(options: DataOptions) {
    const page: number = options.page;
    this.Take = options.itemsPerPage;
    this.Skip = this.Take * (page - 1);

    if (options.sortBy.length > 0) {
      this.SortColumn = options.sortBy[0];
      const desc = options.sortDesc[0];
      this.Sort = desc ? SortDirection.Descending : SortDirection.Ascending;
    } else {
      this.SortColumn = "";
      this.Sort = SortDirection.None;
    }
  }
}

export class PagedQueryRequestFiltered<T> extends PagedQueryRequest {
  Filter: T;

  constructor(f: T) {
    super();
    this.Filter = f;
  }
}
