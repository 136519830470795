
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import LocalizationKey from "../types/Localization/LocalizationKey";
import LocalizationString from "../types/Localization/LocalizationString";
import LocalizationLocale from "../types/Localization/LocalizationLocale";
import Guid from "../types/Guid";
import LocalizationApiService from "../services/LocalizationApiService";
import VMSAutoComplete from "@/components/fields/VMSAutoComplete.vue";

class DisplayItem {
  Text: string = "";
  Id: string = "";
  Item: LocalizationKey = new LocalizationKey();
  constructor(t: string, lk: LocalizationKey) {
    this.Text = t;
    this.Item = lk;
    this.Id = this.Item.Id;
  }
}

@Component({
  components: {
    VMSAutoComplete
  }
})
export default class LocalizationSelector extends Vue {
  @Prop(String) value: string | undefined;
  @Prop(String) label: string | undefined;
  @Prop(String) placeholder: string | undefined;
  @Prop(String) hint: string | undefined;
  @Prop(Array) rules: Function[] | undefined;

  apiService: LocalizationApiService = new LocalizationApiService();
  displayItems: DisplayItem[] = [];
  localeKeyId: string = this.value || Guid.Empty;
  keys: LocalizationKey[] = this.$VmsLocalizations.Keys;
  locales: LocalizationLocale[] = this.$VmsLocalizations.Locales;
  localeId: string = "";
  locale: string = "";
  searchText: string = "";
  confirmAdd: boolean = false;

  onFocus() {
    //gets localizations added by other selectors on the same page
    this.keys = this.$VmsLocalizations.Keys;
    this.setDisplayItems();
  }

  get AddLocalization(): string {
    let locale = this.VmsLocale;
    if (locale) {
      return `${this.$t("AddLocalization")}: ${this.searchText} (${
        locale.Description
      })`;
    } else {
      return this.$t("CantAddLocalizationMissingLocale") as string;
    }
  }

  get ConfirmMsg(): string {
    return `${this.$t("AddLocalization")} '${this.searchText}'?`;
  }

  get VmsLocale(): LocalizationLocale | undefined {
    let localizationLocale: LocalizationLocale | undefined = this.locales.find(
      x => x.Locale == this.$i18n.locale
    );
    if (!localizationLocale)
      localizationLocale = this.locales.find(x => x.Locale === "en");

    return localizationLocale;
  }

  get LocalizationExists(): boolean {
    let found: LocalizationKey | undefined = this.keys.find(
      x =>
        x.LocalizationStrings.find(y => y.Text == this.searchText) !== undefined
    );

    return found !== undefined;
  }

  @Watch("value")
  onValueChanged(val: string | undefined) {
    this.localeKeyId = val || "";
  }

  mounted() {
    this.locale = this.$i18n.locale;
    this.localeId = this.$VmsLocalizations.GetLocaleId(this.locale);
    this.setDisplayItems();
  }

  setDisplayItems() {
    this.displayItems = [];
    this.keys.forEach((lKey: LocalizationKey) => {
      let lString: LocalizationString =
        lKey.LocalizationStrings.find(x => x.LocaleId == this.localeId) ||
        new LocalizationString();
      this.displayItems.push(new DisplayItem(lString.Text, lKey));
    });
  }

  addLocalization() {
    this.confirmAdd = true;
  }

  onConfirmAddClicked() {
    let newKey: LocalizationKey = new LocalizationKey();
    let newString: LocalizationString = new LocalizationString();
    newString.Text = this.searchText;
    newString.Locale = this.VmsLocale || new LocalizationLocale();
    newString.LocaleId = newString.Locale.Id;
    newKey.LocalizationStrings.push(newString);

    this.confirmAdd = false;

    if (newString.LocaleId !== Guid.Empty) {
      this.apiService.CreateLocalizationKey(newKey).then(resp => {
        if (resp.Success) {
          this.$VmsLocalizations.Load().then(x => {
            this.keys = this.$VmsLocalizations.Keys;
            this.setDisplayItems();
            this.localeKeyId = resp.Value;
            (this.$refs.autocomplete as any).isMenuActive = false;
            this.onInput();
          });
        }
      });
    }
  }

  onInput() {
    this.$emit("input", this.localeKeyId);
  }
}
